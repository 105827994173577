<div class="ui padded container">
  <app-article-filter (applied)="applyArticleFilter($event)"
                      [articlesFilter]="articlesFilter"
                      [numberOfArticles]="collectionSize"
                      [topics]="topics" [subjectAreas]="subjectAreas"
                      [isAdmin]="userRoles.includes(clientRoles.IS_ADMIN)"></app-article-filter>
  <div class="ui segment">
    <app-articles-sort (sortParamSelected)="changeSort($event)"></app-articles-sort>
  </div>
  <div class="ui segment">
    <sui-accordion>
      <sui-accordion-panel>
        <h3 title>
          <i class="dropdown icon"></i>
          Hinweise zur Suche
        </h3>
        <div content>
          <ul>
            <li>Bei der Suchfunktion in FIT wird nicht zwischen Groß- und Kleinschreibung unterschieden</li>
            <li>Sofern Sie nach Abkürzungen recherchiert haben, prüfen Sie bitte, ob Sie stattdessen das Langwort
              suchen können
            </li>
          </ul>
        </div>
      </sui-accordion-panel>
    </sui-accordion>
  </div>
  <!--ARTIKEL ITEMS-->
  <div class="top attached ui segment">
    <div class="ui divided items">
      @for (article of articles; track article._id) {
        <div class="item">
          <div class="content">
            <a class="header" [routerLink]="[article._id]">
              <i class="sticky note outline icon"></i>
              {{ article?.title }}
            </a>
            @if (isFavoriteArticle(article)) {
              <i class="yellow favorite icon active" (click)="removeArticleFromFavorites(article)"
                 (keydown)="addArticleToFavorites(article)" tabindex="-1"
                 title="Artikel aus Lesezeichen entfernen!"></i>
            } @else {
              <i class="black favorite icon active" (click)="addArticleToFavorites(article)"
                 (keydown)="addArticleToFavorites(article)" tabindex="-1"
                 title="Artikel zu Lesezeichen hinzufügen!"></i>
            }
            <div class="meta">
              @if (userRoles.includes(clientRoles.IS_AUTHOR)) {
                <a>Erstellt/Bearbeitet am: {{ article?.updatedAt | date }}</a>
              }
              <br>
              <a>{{ article?.registerForm }} bis zum: {{ article?.registerDate | date }}</a>
            </div>
            <div class="shortDescription">{{ article?.shortDescription }}</div>
            @if (!article.shortDescription) {
              <div class="description" [innerHTML]="shortenDescription(article?.description)"></div>
            }
            <div class="extra">
              <a class="ui right floated primary button" [routerLink]="['/articles', article._id]">
                Zum Artikel
                <i class="right chevron icon"></i>
              </a>
              @if (userIsAuthorFromArticle(article.authorId) || userRoles.includes(clientRoles.ACCESS_ADMIN)) {
                <a class="ui right floated secondary button" [routerLink]="['/author/articles/edit', article._id]">
                  <i class="edit icon"></i>
                  Bearbeiten
                </a>
              }
            </div>
          </div>
        </div>
      } @empty {
        <div class="item"> Keine Artikel vorhanden!</div>
      }
    </div>
  </div>
  <!--PAGINATION-->
  <div class="centered">
    <sui-pagination [maxSize]="5" [canRotate]="true" [hasEllipses]="true" [collectionSize]="collectionSize"
                    [pageSize]="articlesFilter.pageSize!" [hasNavigationLinks]="true" [hasBoundaryLinks]="true"
                    [(page)]="articlesFilter.selectedPage!" (pageChange)="handlePageChange()">
    </sui-pagination>
  </div>
</div>
